/* what's new section */
.whatsnew {
  padding: 15px;
}
.whatsnew span {
  cursor: pointer;
}
.whatsnew h1 {
  font-size: 1.2em;
  margin: 0 0 15px;
}
.whatsnew .mdl_chip {
  height: 24px;
  line-height: 24px;
}
.whatsnew .material-icons {
  vertical-align: middle;
  padding-right: 5px;
  font-size: 18px;
  max-width: 20px;
}
.whatsnew .mdl-chip__text {
  font-size: 12px;
}
