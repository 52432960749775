*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  color: #555;
  font-family: Roboto, 'Helvetica Neue', 'Calibri', 'Liberation Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
}
h1,
h2,
h3,
h4,
h5 {
  color: #333;
}
a {
  text-decoration-skip: ink;
  color: rgb(211, 45, 101);
}

/* sidebar */
.logo-img {
  width: 100%;
}
.demo-drawer-header a {
  text-decoration: none;
  color: inherit;
  padding-top: 5px;
}

/* prevent top-of-page spasms */
.map-container {
  height: 600px;
}

/* fix for mdl menu on linux screwing up page scrolling */
.mdl-menu,
.mdl-menu__container,
.mdl-menu__outline {
  z-index: 0;
}

.mdl-layout__header-row,
.mdl-layout__header {
  height: 54px !important;
  min-height: 54px !important;
  line-height: 54px !important;
}

/* select group */
.selectgroup-link {
  padding-left: 6px;
  white-space: nowrap;
}

/* for side by side elements */
.flexcontainer {
  display: flex;
  flex-direction: row;
}
.flexcontainer .flex-item {
  flex-grow: 1;
  width: 50%;
  padding: 10px;
}

.undermap-controls button {
  margin-bottom: 5px;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

input,
textarea {
  outline: none;
}

/* hide content on phones */
@media (max-width: 520px) {
  .phoneHide {
    display: none;
  }
}
